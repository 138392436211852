<!--  -->
<template>
    <div class=''>
        <!-- <div style="font-size: 28px">复核记录详情</div> -->
        <!--    显示数据包括病人姓名（用户名）、申请复核时间、症状备注（纯文本，使用按钮打开弹出框显示）。
                                不显示的数据包括医生ID，病人ID，预约编号aID，邀请链接（不确定是否存在这里）。以上数据考虑是否拆成两组甚至更多组来存储-->
        <!-- <el-divider></el-divider> -->
        <el-row class="info">
            <el-col :span="4"><span style="font-size:18px"> 姓名：{{ realName }} </span></el-col>
            <el-col :span="8"><span style="font-size:18px"> 申请时间：{{ checkCreateTime }} </span></el-col>
            <el-col :span="8"><span style="font-size:18px"> 症状描述：{{ userDescribe }} </span></el-col>
            <el-col :span="4" style="font-size:18px"><span> 复核状态：{{ (checkStatus === 0 || checkStatus === '0') ? '待复核' : '已复核'
            }} </span>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col :span="18">
                <SingoReport :userId="this.uid" :recordId="this.recordId" :createTimeId="this.createTime"
                    :durationId="this.duration" v-if="this.type == 'ecg_static'" />
                <SingoHolterReport :userId="this.uid" :recordId="this.recordId" :createTimeId="this.createTime"
                    :durationId="this.duration" v-if="this.type == 'ecg_dynamic'" />
                <EightReport :userId="this.uid" :recordId="this.recordId" :createTimeId="this.createTime"
                    :durationId="this.duration" v-if="this.type == 'ecg8_static'" />
                <EightHolterReport :userId="this.uid" :recordId="this.recordId" :createTimeId="this.createTime"
                    :durationId="this.duration" v-if="this.type == 'ecg8_dynamic'" />
                <!-- <img style="width: 95%; vertical-align: middle; display: inline-block"
                                                src="../../../../public/img/example.jpg" /> -->
                <!-- <el-button @click="WatchReport()">查看报告</el-button> -->
            </el-col>
            <el-col :span="6">
                <el-row>
                    <div style="font-size:18px">
                        复核结论
                    </div>
                    <br>
                    <!-- <el-input type="textarea" rows="10" v-model="DocConclusion"></el-input> -->
                    <el-checkbox :indeterminate="isIndeterminate" v-model="ChooseAll"
                        @change="handleChooseAllChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="ChoosedDiseases" @change="handleChooseChange">
                        <el-checkbox v-for="Disease in Diseases" :label="Disease" :key="Disease">{{ Disease }}</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox-group v-model="ChooseOther" @change="handleChooseOther">
                        <el-checkbox :label="Other">{{ Other }}</el-checkbox>
                    </el-checkbox-group>
                    <el-input v-if="ChooseOther" v-model="OtherContent" placeholder="请输入内容"
                        @change="handleFinishOtherContent"></el-input>
                </el-row>
                <br>
                <br>
                <el-row>
                    <div style="font-size:18px">
                        电子签名
                        <el-button type="primary" plain icon="el-icon-edit" @click="makeSignature()">添加签名</el-button>
                        <img style="width:100%" :src="Signature" alt="" v-if="Signature">
                    </div>
                </el-row>
                <br>
                <el-row>
                    <el-button type="primary" style="font-size: 18px;" @click="submitCheck()">提交复核</el-button>
                </el-row>
            </el-col>
        </el-row>
        <el-dialog title="手写签名" :visible.sync="SignatureVisible" width="600px" :before-close="handleClose">
            <VueSignaturePad width="500px" height="200px" ref="signaturePad" />
            <div>
                <el-button @click="save">保存</el-button>
                <el-button @click="undo">撤销</el-button>
            </div>
        </el-dialog>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import SingoReport from '../../personal_module/records/History/HistorySingo2.vue'
import SingoHolterReport from '../../personal_module/records/History/HistorySingoHolter2.vue'
import EightReport from '../../personal_module/records/History/HistoryEight2.vue'
import EightHolterReport from '../../personal_module/records/History/HistoryEightHolter2.vue'

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
        SingoReport,
        SingoHolterReport,
        EightReport,
        EightHolterReport
    },
    data() {
        //这里存放数据
        return {
            uid: '',
            realName: '',
            recordId: '',
            type: '',
            checkDoneTime: '',
            checkCreateTime: '',
            userDescribe: '',
            checkStatus: '',
            DocConclusion: '',
            DocConId: '',
            Signature: '',
            SignatureVisible: false,
            header: {},

            ChooseAll: false,
            ChoosedDiseases: [],
            Diseases: ['窦性心动过速', '窦性心动过缓', '窦性心律不齐', '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸搏', '室性逸搏', '房颤', '心梗相关疾病',],
            isIndeterminate: false,
            ChooseOther: '',
            Other: '其他',
            OtherContent: '',
        };
    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: {
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        }
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        // 多选框
        handleChooseAllChange(val) {
            this.ChoosedDiseases = val ? this.Diseases : [];
            this.isIndeterminate = false;
        },
        handleChooseChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.Diseases.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.Diseases.length;
        },
        handleChooseOther(value) {
            if (!value) {
                this.OtherContent = ''
            }
        },
        handleFinishOtherContent() {
            this.DocConclusion = this.ChoosedDiseases.concat(this.OtherContent) // 把【其他】加入到结论中
        },
        // 基础信息
        getinfo() {
            this.$http.adornHeader(this.header)
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                // headers: this.$http.adornHeader(this.header), 
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.realName = data.realName
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 添加复核结论
        submitCheck() {
            if (!this.ChooseOther) {
                this.DocConclusion = this.ChoosedDiseases
            }
            console.log(this.DocConclusion)
            // this.$http({
            //     url: this.$http.adornUrl('/doctor/ecgRecord/doneConsult'),
            //     method: 'post',
            //     data: this.$http.adornData({
            //         // conId: 1, conclusion: 'hello'
            //         conId: this.DocConId, conclusion: JSON.stringify(this.ChoosedDiseases),
            //     }),
            // }).then(({ data }) => {
            //     if (data && data.code === 0) {
            //         this.$message({
            //             message: '添加复核结论成功',
            //             type: 'success'
            //         })
            //     } else {
            //         // this.getCaptcha()
            //         this.$message.error(data.msg)
            //     }
            // })
        },
        WatchReport() {
            this.$router.push({
                name: 'HistorySingo2',
                query: {
                    uid: this.uid,
                    staticRecordId: this.recordId,
                }
            })
        },
        makeSignature() {
            this.SignatureVisible = true
        },
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            this.Signature = data;
            this.updateSignature()
        },
        handleClose(done) {
            done();
        },
        // 接口10：医生查询个人签名
        getSignature() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorinfo/getSignature'),
                method: 'get',
                params: this.$http.adornParams({ docId: this.$store.state.doctor_station.docid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // console.log(data)
                    this.Signature = data.signature
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        // 接口11：医生上传个人签名
        updateSignature() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorinfo/updateSignature'),
                method: 'post',
                data: this.$http.adornData({ docId: this.$store.state.doctor_station.docid, signature: this.Signature })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.$store.state.module.crossPlatform) {
            this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
        }
        this.crossPlatformStatus = true,
            this.uid = this.$route.query.uid,
            this.recordId = this.$route.query.recordId,
            this.createTime = this.$route.query.recordTime,
            this.duration = this.$route.query.duration,
            this.type = this.$route.query.type,
            this.checkDoneTime = this.$route.query.checkTime,
            this.checkCreateTime = this.$route.query.checkCreateTime,
            this.userDescribe = this.$route.query.userDescribe,
            this.checkStatus = this.$route.query.checkStatus,
            this.DocConclusion = this.$route.query.DocConclusion,
            this.DocConId = this.$route.query.DocConId
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getinfo()
        this.getSignature()

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped></style>